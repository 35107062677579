import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil'

import store from 'store'
import { ViewName } from 'types/view-name'

import { VIEW_DESCRIPTORS } from './consts'
import { IconContainer } from './SideMenu.styles'

export interface SideMenuButtonProps {
  'data-test': string
  viewName: ViewName
}

export const SideMenuButton: React.FC<SideMenuButtonProps> = ({
  'data-test': dataTest = 'side-menu-button',
  viewName,
}) => {
  const viewDescriptor = useMemo(
    () =>
      VIEW_DESCRIPTORS.find(
        ({ viewName: descriptorViewName }) => descriptorViewName === viewName,
      ),
    [viewName],
  )

  const { label, Icon } = viewDescriptor

  const [selectedView, setSelectedView] = useRecoilState(
    store.view.selectedView,
  )

  const isSelected = selectedView === viewName
  const selectView = () => setSelectedView(viewName)

  return (
    <IconContainer
      data-test={`${dataTest}.element`}
      data-test-arg={viewName}
      key={viewName}
      selected={isSelected}
      onClick={selectView}
    >
      <Icon />
      <div data-test={`${dataTest}.label`}>{label}</div>
    </IconContainer>
  )
}
