import { FC } from 'react'
import { useDrop } from 'react-dnd'
import 'twin.macro'

import TrashIcon from 'assets/icons/trash.svg'
import { DraggableItemTypes } from 'lib/draggable-item-types'

import { Container, Text, DeleteZoneText } from './TileDropZone.styles'

export interface TileDropZoneProps {
  'data-test': string
}

export const TileDropZone: FC<TileDropZoneProps> = ({
  'data-test': dataTest = 'tile-drop-zone',
}) => {
  const [, drop] = useDrop(() => ({
    accept: DraggableItemTypes.TILE,
  }))

  return (
    <Container data-test={`${dataTest}.container`} ref={drop}>
      <TrashIcon tw="fill-brand-red h-[24px] w-[24px]" />
      <DeleteZoneText></DeleteZoneText>
      <Text>Drag tile here or press X to delete this tile</Text>
    </Container>
  )
}
