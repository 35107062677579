import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Blotter from 'components/Blotter'
import Controls from 'components/Controls'
import Header from 'components/Header'
import QuickRFQContainer from 'components/QuickRFQContainer'
import SideMenu from 'components/SideMenu'

import ConnectedDrawer from './ConnectedDrawer'
import Content from './Content'
import {
  Container,
  ContentContainer,
  DashboardContainer,
  BlotterContainer,
  HeaderContainer,
} from './Dashboard.styles'

export interface DashboardProps {
  'data-test': string
}

const Dashboard: React.FC<DashboardProps> = ({
  'data-test': dataTest = 'dashboard',
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DashboardContainer data-test={`${dataTest}.container`}>
        <SideMenu data-test={`${dataTest}.side-menu`} />
        <Container>
          <HeaderContainer>
            <Header data-test={`${dataTest}.header`} />
          </HeaderContainer>
          <ContentContainer>
            <Controls data-test={`${dataTest}.control`} />
            <Content data-test={`${dataTest}.content`} />
          </ContentContainer>
          <BlotterContainer>
            <Blotter data-test={`${dataTest}.blotter`} />
          </BlotterContainer>
        </Container>
      </DashboardContainer>
      <ConnectedDrawer data-test={`${dataTest}.connected-drawer`}>
        <QuickRFQContainer data-test={`${dataTest}.quick-rfq-container`} />
      </ConnectedDrawer>
    </DndProvider>
  )
}

export default Dashboard
