import { FC } from 'react'
import { useRecoilValue } from 'recoil'

import Button from 'components/Button'
import TabButton from 'components/TabButton'
import TileDropZone from 'components/TileDropZone'
import { useAddTile } from 'hooks/streamingTiles'
import { useCurrentGroup } from 'hooks/tiles/useCurrentGroup'
import store from 'store'

import {
  ControlsContainer,
  GroupSelectorContainer,
  PlusSquareIcon,
} from './Controls.styles'

export interface ControlsProps {
  'data-test': string
}

export const Controls: FC<ControlsProps> = ({
  'data-test': dataTest = 'controls',
}) => {
  const [selectedGroupId, groupIds, handlers] = useCurrentGroup()

  const addTile = useAddTile()

  const tileBeingDragged = useRecoilValue(store.tileSlice.tileBeingDragged)

  return (
    <ControlsContainer data-test={`${dataTest}.container`}>
      <GroupSelectorContainer data-test={`${dataTest}.group-selector`}>
        {groupIds.map((groupId) => (
          <TabButton
            key={groupId}
            data-test={`${dataTest}.set-selected-group-id-button`}
            data-test-arg={groupId}
            selected={selectedGroupId === groupId}
            onClick={handlers[groupId]}
          >
            {groupId}
          </TabButton>
        ))}
      </GroupSelectorContainer>
      {tileBeingDragged >= 0 && (
        <TileDropZone data-test={`${dataTest}.tile-drop-zone`} />
      )}
      <Button data-test={`${dataTest}.add-pair`} onClick={addTile}>
        <PlusSquareIcon />
        <span>Add Pair</span>
      </Button>
    </ControlsContainer>
  )
}
