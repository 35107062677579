import { FC, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import tw from 'twin.macro'

import SquaredPlus from 'assets/icons/plus-square.svg'
import Button from 'components/Button'
import RFQTile from 'components/RFQTile'
import store from 'store'

const SquaredPlusIcon = tw(SquaredPlus)`w-[18px] mr-1.5 fill-light-500`

export interface QuickRFQContainerProps {
  'data-test': string
}

export const QuickRFQContainer: FC<QuickRFQContainerProps> = ({
  'data-test': dataTest = 'quick-rfq-container',
}) => {
  const quickRFQCurrencyPair = useRecoilValue(store.quickRFQ.currencyPair)
  const selectedGroupId = useRecoilValue(store.rfqTiles.selectedGroupId)
  const setCurrentRFQPair = useSetRecoilState(
    store.rfqTiles.groupById(selectedGroupId),
  )

  const addPairToCurrentRFQGroup = useCallback(() => {
    setCurrentRFQPair((prev) => [...prev, quickRFQCurrencyPair])
  }, [setCurrentRFQPair, quickRFQCurrencyPair])

  if (!quickRFQCurrencyPair) return

  return (
    <div data-test={`${dataTest}.container`}>
      <RFQTile
        data-test={`${dataTest}.tile`}
        currencyPair={quickRFQCurrencyPair}
      />
      <Button
        data-test={`${dataTest}.submit`}
        onClick={addPairToCurrentRFQGroup}
        tw="mt-2"
      >
        <SquaredPlusIcon />
        <div>Save this RFQ</div>
      </Button>
    </div>
  )
}
