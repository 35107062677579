import React from 'react'
import { useRecoilValue } from 'recoil'

import RFQTileGroup from 'components/RFQTileGroup'
import store from 'store'

export interface SelectedRfqTilesGroupProps {
  'data-test': string
}

const SelectedRfqTilesGroup: React.FC<SelectedRfqTilesGroupProps> = ({
  'data-test': dataTest = 'selected-rfq-tiles-group',
}) => {
  const groupId = useRecoilValue(store.rfqTiles.selectedGroupId)
  return (
    <RFQTileGroup data-test={`${dataTest}.rfq-tile-group`} groupId={groupId} />
  )
}

export default SelectedRfqTilesGroup
