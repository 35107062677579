import { useMemo } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'

import store from 'store'

type UseCurrentGroupSig = [string, Array<string>, Record<string, () => void>]

export const useCurrentGroup = (): UseCurrentGroupSig => {
  const groupIds = useRecoilValue(store.tileSlice.selectedGroupIds)
  const [selectedGroupId, setSelectedGroupid] = useRecoilState(
    store.tileSlice.selectedGroupId,
  )

  const handlers = useMemo(
    () =>
      Object.fromEntries(
        groupIds.map((groupId) => [groupId, () => setSelectedGroupid(groupId)]),
      ),
    [groupIds, setSelectedGroupid],
  )

  return [selectedGroupId, groupIds, handlers]
}
