import styled, { css } from 'styled-components'
import tw from 'twin.macro'

import Logo from 'assets/icons/logo.svg'

interface IconContainerProps {
  selected?: boolean
}

export const Container = tw.div`
  flex
  flex-col
  items-center
  gap-1
  h-full
  max-w-min
  pl-4
`

export const IconContainer = styled.div<IconContainerProps>`
  ${tw`
      !fill-white
      w-[87px]
      h-[81px]
      flex
      flex-col
      items-center
      justify-center
      gap-[6px]
      cursor-pointer
      text-light-400
      font-ringside-narrow
      font-weight[300]
      text-[12px]
      leading-[14px]
      rounded-[4px]
    `}

  ${({ selected }) =>
    selected
      ? tw`!fill-brand-orange bg-light-900  text-light-200`
      : css`
          ${tw`
              hover:text-light-300
              hover:bg-light-900
            `}
        `}
`

export const LogoIcon = tw(Logo)`
  h-[29px]
  w-[22px]
  fill-brand-light
  mt-5
  mb-9
`
