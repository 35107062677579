import tw from 'twin.macro'

import PlusSquare from 'assets/icons/plus-square.svg'

export const ControlsContainer = tw.div`
  flex
  gap-0.5
  py-0.5
  justify-between
  h-[50px]
`
export const GroupSelectorContainer = tw.div`
  flex gap-[2px]
`

export const PlusSquareIcon = tw(
  PlusSquare,
)`fill-light-600 w-[16px] mt-[-3px] mr-1.5`
