import { useMemo, useState, useEffect } from 'react'

import { BalanceResponse } from 'types/api/balance'

import { useSocket } from './useSocket'
import { useSubscription } from './useSubscription'

export const useGlobalBalance = (equivalentCurrency: string) => {
  const [balances, setBalances] = useState<BalanceResponse[]>([])
  const socket = useSocket()
  const stream = useMemo(
    () => ({
      name: 'Balance',
      EquivalentCurrency: equivalentCurrency,
    }),
    [equivalentCurrency],
  )

  const response = useSubscription<BalanceResponse>(socket, stream)

  useEffect(() => {
    if (response && response.length > 0) {
      setBalances((prev) => {
        const next = Array.from(prev)

        for (const balance of response) {
          const index = next.findIndex(
            (prevBalance) => prevBalance.Currency === balance.Currency,
          )
          if (index >= 0) next[index] = balance
          else next.push(balance)
        }

        return next
      })
    }
  }, [response, setBalances])

  return balances
}
