import { useRef } from 'react'

import { ExposureResponseData } from 'types/api/exposure-response'

import { useSocket } from './useSocket'
import { useSubscription } from './useSubscription'

export const useExposure = () => {
  const socket = useSocket()
  const stream = useRef({
    name: 'Exposure',
  })

  const response = useSubscription<ExposureResponseData>(socket, stream.current)

  return response.length && response[0]
}
