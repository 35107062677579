import tw from 'twin.macro'

export const Container = tw.div`
    flex-1
    flex
    flex-col
`

export const DashboardContainer = tw.div`
    relative
    bg-brand-dark
    w-full
    h-full
    overflow-hidden
    flex
`

export const HeaderContainer = tw.div`
    px-[101px]
`

export const ContentContainer = tw.div`
    flex-1
    flex
    flex-col
    gap-4
    overflow-auto
    mt-6
    mb-5
    pb-9
    px-[101px]

`

export const BlotterContainer = tw.div`
    px-[101px]
`
