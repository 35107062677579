import React, { useMemo } from 'react'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { FEATURE_NAMES, VIEW_DESCRIPTORS } from './consts'
import { Container, LogoIcon } from './SideMenu.styles'
import { SideMenuButton } from './SideMenuButton'

export interface SideMenuProps {
  'data-test': string
}

export const SideMenu: React.FC<SideMenuProps> = ({
  'data-test': dataTest = 'side-menu',
}) => {
  const viewEnablements = useFeatureFlags(FEATURE_NAMES)

  const enabledViews = useMemo(
    () =>
      VIEW_DESCRIPTORS.filter(
        ({ featureName }) => viewEnablements[featureName],
      ),
    [viewEnablements],
  )

  return (
    <Container data-test={`${dataTest}.container`}>
      <LogoIcon />
      {enabledViews.map(({ viewName }) => (
        <SideMenuButton
          data-test={`${dataTest}.side-menu-button`}
          key={viewName}
          viewName={viewName}
        />
      ))}
    </Container>
  )
}
