import React from 'react'
import { useRecoilValue } from 'recoil'

import GroupsNavigator from 'components/GroupsNavigator'
import store from 'store'

export interface ContentProps {
  'data-test': string
}

/**
 * Dashboard/Content
 * Defer to the, itself store-connected, selected view.
 */
const Content: React.FC<ContentProps> = ({
  'data-test': dataTest = 'content',
}) => {
  const selectedView = useRecoilValue(store.view.selectedView)

  return ['trading', 'rfq'].includes(selectedView) ? (
    <GroupsNavigator
      data-test={`${dataTest}.group-navigator`}
      selectedView={selectedView}
    />
  ) : null
}

export default Content
