import { map } from 'lodash'
import { FC } from 'react'
import tw from 'twin.macro'

import { useExposure } from 'hooks/useExposure'
import { useGlobalBalance } from 'hooks/useGlobalBalance'
import { getCurrencyIconBySymbol } from 'lib/app-util'
import { formatDecimal, compress } from 'lib/decimal-util'

import { BalanceHeaderItem } from './BalanceHeaderItem'

const Container = tw.div`flex justify-between`
const BalanceContainer = tw.div`flex gap-6 flex-wrap`
const CreditContainer = tw.div`flex gap-4 flex-wrap`

const inMillions = (num: string) => {
  const parsed = parseFloat(num)
  return Math.floor(parsed / Math.pow(10, 6)).toString()
}

const asPercent = (num: string, denom: string) => {
  const parsedNum = parseFloat(num)
  const parsedDenom = parseFloat(denom)
  if (parsedDenom === 0) return '0'
  return ((parsedNum / parsedDenom) * 100).toPrecision(3).concat('%')
}

export interface BalanceHeaderProps {
  'data-test': string
}

export const BalanceHeader: FC<BalanceHeaderProps> = ({
  'data-test': dataTest = 'balance-header',
}) => {
  const credit = useExposure()
  const balances = useGlobalBalance('USD')

  return (
    <Container data-test={`${dataTest}.container`}>
      {credit && (
        <CreditContainer>
          <BalanceHeaderItem
            data-test={`${dataTest}.total-equity`}
            label="Total Equity"
            value={credit.ExposureLimit}
            icon={getCurrencyIconBySymbol(credit.ExposureCurrency)}
            formatter={formatDecimal}
          />
          <BalanceHeaderItem
            data-test={`${dataTest}.credit-line`}
            label="Credit Line"
            value={credit.Exposure}
            icon={getCurrencyIconBySymbol(credit.ExposureCurrency)}
            formatter={formatDecimal}
            totalValue={credit.ExposureLimit}
            totalValueFormatter={inMillions}
          />
          <BalanceHeaderItem
            data-test={`${dataTest}.credit-utilization`}
            label="Credit Utilization"
            value={asPercent(credit.Exposure, credit.ExposureLimit)}
          />
        </CreditContainer>
      )}
      <BalanceContainer>
        {map(balances, ({ Currency, AvailableAmount }) => (
          <BalanceHeaderItem
            data-test={`${dataTest}.balance`}
            data-test-arg={Currency}
            key={Currency}
            label={Currency}
            value={AvailableAmount}
            formatter={compress}
            icon={getCurrencyIconBySymbol(Currency)}
          />
        ))}
      </BalanceContainer>
    </Container>
  )
}
