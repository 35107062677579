import React from 'react'
import { useRecoilValue } from 'recoil'

import StreamingTileSlotConnector from 'components/StreamingTileSlotConnector'
import store from 'store'
import { GroupId } from 'types/group'

import { Container } from './StreamingTileGroup.styles'

export interface StreamingTileGroupProps {
  'data-test': string
  groupId: GroupId
}

const StreamingTileGroup: React.FC<StreamingTileGroupProps> = ({
  'data-test': dataTest = 'tile-group',
  groupId,
}) => {
  const group = useRecoilValue(store.streamingTiles.groupById(groupId))
  return (
    <Container data-test={`${dataTest}.container`}>
      {group.map((_, index) => (
        <StreamingTileSlotConnector
          data-test={`${dataTest}.connector`}
          key={index}
          groupId={groupId}
          position={index}
        />
      ))}
    </Container>
  )
}

export default StreamingTileGroup
