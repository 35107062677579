import React from 'react'
import 'twin.macro'

import BalancesIcon from 'assets/icons/balances.svg'
import RFQIcon from 'assets/icons/rfq.svg'
import SettingsIcon from 'assets/icons/settings.svg'
import SupportIcon from 'assets/icons/support.svg'
import TradingIcon from 'assets/icons/trading.svg'
import { ViewName } from 'types/view-name'

type ViewDescriptor = {
  featureName: string
  viewName: ViewName
  dataTestId?: string
  label: string
  Icon: React.FC
}

export const VIEW_DESCRIPTORS = [
  {
    featureName: 'view.nav.trading',
    viewName: 'trading',
    label: 'Trading',
    Icon: () => <TradingIcon tw="w-[32px] h-[32px]" />,
  },
  {
    featureName: 'view.nav.rfq',
    viewName: 'rfq',
    dataTestId: 'nav-rfq-item',
    label: 'RFQ',
    Icon: () => <RFQIcon tw="w-[32px] h-[32px]" />,
  },
  {
    featureName: 'view.nav.balances',
    viewName: 'balances',
    label: 'Balances',
    Icon: () => <BalancesIcon tw="w-[32px] h-[32px]" />,
  },
  {
    featureName: 'view.nav.support',
    viewName: 'support',
    label: 'Support',
    Icon: () => <SupportIcon tw="w-[32px] h-[32px]" />,
  },
  {
    featureName: 'view.nav.settings',
    viewName: 'settings',
    label: 'Settings',
    Icon: () => <SettingsIcon tw="w-[32px] h-[32px]" />,
  },
] as ViewDescriptor[]

export const FEATURE_NAMES = VIEW_DESCRIPTORS.map(
  ({ featureName }) => featureName,
)
