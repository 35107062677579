import React from 'react'
import { useRecoilValue } from 'recoil'

import StreamingTileGroup from 'components/StreamingTileGroup'
import store from 'store'

export interface SelectedStreamingTilesGroupProps {
  'data-test': string
}

const SelectedStreamingTilesGroup: React.FC<
  SelectedStreamingTilesGroupProps
> = ({ 'data-test': dataTest = 'selected-streaming-tile-group' }) => {
  const groupId = useRecoilValue(store.streamingTiles.selectedGroupId)
  return (
    <StreamingTileGroup
      data-test={`${dataTest}.tile-group`}
      groupId={groupId}
    />
  )
}

export default SelectedStreamingTilesGroup
