import ClientSideToplevel from 'components/ClientSideToplevel'
import Dashboard from 'components/Dashboard'

const DashboardPage = () => {
  return (
    <ClientSideToplevel>
      <Dashboard data-test="home.dashboard" />
    </ClientSideToplevel>
  )
}

export default DashboardPage
