import tw from 'twin.macro'

export const Container = tw.div`
  flex
  justify-center
  gap-4 
  w-[566px] 
  h-[40px] 
  items-center 
  bg-ui-red 
  bg-opacity-10 
  border-dashed
  border-ui-red
  border-[1px]
  rounded-[4px]
  `

export const DeleteZoneText = tw.div`text-ui-red`

export const Text = tw.span`font-ringside-narrow font-weight[250] text-[12px] leading-[14px] text-light-500`
