import { FC, ReactElement } from 'react'
import tw from 'twin.macro'

const Container = tw.div`flex-col gap-2 font-ringside-narrow`
const Label = tw.div`text-light-400 text-[12px] font-weight[300]`
const Value = tw.div`text-white text-[16px] font-weight[350] line-height[19px]`
const ValueContainer = tw.div`flex gap-1`
const TotalValue = tw.div`flex text-[11px] pl-1 text-white`

type Formatter = (arg: string) => string

export interface BalanceHeaderItemProps {
  'data-test': string
  'data-test-arg'?: string
  label: string
  value: string
  formatter?: Formatter
  icon?: () => ReactElement
  totalValue?: string
  totalValueFormatter?: Formatter
}

export const BalanceHeaderItem: FC<BalanceHeaderItemProps> = ({
  'data-test': dataTest = 'balance-header-item',
  'data-test-arg': dataTestArg,
  label,
  value,
  formatter,
  icon,
  totalValue,
  totalValueFormatter,
}) => {
  const computedValue = formatter ? formatter(value) : value
  const computedTotalValue = totalValueFormatter
    ? totalValueFormatter(totalValue)
    : totalValue
  return (
    <Container data-test={`${dataTest}.container`} data-test-arg={dataTestArg}>
      <Label data-test={`${dataTest}.label`}>{label}</Label>
      <ValueContainer>
        {icon && icon()}
        <Value data-test={`${dataTest}.value`}>{computedValue}</Value>
        {totalValue && (
          <TotalValue data-test={`${dataTest}.total-value`}>
            / {computedTotalValue}M
          </TotalValue>
        )}
      </ValueContainer>
    </Container>
  )
}
