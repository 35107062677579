import React from 'react'

import { ViewName } from 'types/view-name'

import { Container } from './GroupsNavigator.styles'
import SelectedRfqTilesGroup from './SelectedRfqTilesGroup'
import SelectedStreamingTilesGroup from './SelectedStreamingTilesGroup'

export interface GroupsNavigatorProps {
  'data-test': string
  selectedView: ViewName
}

const GroupsNavigator: React.FC<GroupsNavigatorProps> = ({
  'data-test': dataTest = 'group-navigator',
  selectedView,
}) => (
  <Container data-test={`${dataTest}.container`}>
    {'trading' === selectedView ? (
      <SelectedStreamingTilesGroup
        data-test={`${dataTest}.selected-streaming`}
      />
    ) : 'rfq' === selectedView ? (
      <SelectedRfqTilesGroup data-test={`${dataTest}.selected-rfq`} />
    ) : null}
  </Container>
)

export default GroupsNavigator
