import { FC } from 'react'
import tw from 'twin.macro'

import BalanceHeader from 'components/BalanceHeader'

const Container = tw.div`min-h-[70px] pl-8 pt-5 pb-[3px] w-full border-bottom[1px solid] border-light-800`

export interface HeaderProps {
  'data-test': string
}

export const Header: FC<HeaderProps> = ({
  'data-test': dataTest = 'header',
}) => {
  return (
    <Container data-test={`${dataTest}.container`}>
      <BalanceHeader data-test={`${dataTest}.balance-header`} />
    </Container>
  )
}
