import { isNil } from 'lodash'
import React, { useCallback } from 'react'
import { useRecoilState } from 'recoil'

import Drawer from 'components/Drawer'
import store from 'store'

export interface ConnectedDrawerProps {
  'data-test': string
  children: JSX.Element | JSX.Element[]
}

const ConnectedDrawer: React.FC<ConnectedDrawerProps> = ({
  'data-test': dataTest = 'connected-drawer',
  children,
}) => {
  const [currencyPair, setCurrencyPair] = useRecoilState(
    store.quickRFQ.currencyPair,
  )

  const clearCurrencyPair = useCallback(
    () => setCurrencyPair(null),
    [setCurrencyPair],
  )

  return (
    <Drawer
      data-test={`${dataTest}.drawer`}
      isShown={!isNil(currencyPair)}
      onHide={clearCurrencyPair}
    >
      {children}
    </Drawer>
  )
}

export default ConnectedDrawer
